import React, { Component } from 'react';
import './App.css';
import Home from './Components/home/home.component';
import ReactGA from 'react-ga';


class App extends Component {

  componentDidMount = () => {
    this.initializeReactGA()
  }


  initializeReactGA() {
    ReactGA.initialize('UA-170822324-1');
    ReactGA.pageview('/');
  }
  render() {


    // const firebaseConfig = {
    //   apiKey: "AIzaSyAWDuJMpasAcMVpX83vF330V2rjvVH2zC0",
    //   authDomain: "covid19-82817.firebaseapp.com",
    //   databaseURL: "https://covid19-82817.firebaseio.com",
    //   projectId: "covid19-82817",
    //   storageBucket: "covid19-82817.appspot.com",
    //   messagingSenderId: "470314604006",
    //   appId: "1:470314604006:web:b5509afdd2293f5b127227",
    //   measurementId: "G-226JS5EZ0W"
    // };




    return (
      <div className="App">
        <Home />
      </div>
    );
  }
}


export default App;
