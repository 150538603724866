import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Countries } from '../data';
import './searchBar.styles.scss'

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    testtt = (e, value) => this.props.set(value)

    render() {
        // let { country } = this.state
        return (
            <Autocomplete
                id="combo-box-demo"
                className='Search-bar'
                // value={Countries[0]}
                options={Countries}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                onChange={this.testtt}
            />


        );
    }
}

export default SearchBar;