import React, { Component } from 'react';
import SearchBar from '../searchBar/searchBar.component';
import './tailwind.min.css'
import './home.styles.scss'
import { faSkullCrossbones, faVirus, faSmileBeam, faProcedures } from '@fortawesome/free-solid-svg-icons'
import ReactCountryFlag from "react-country-flag"
import DataCard from '../Data Card/dataCard.component';
import moment from 'moment';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: '',
            foundData: false
        }
    }

    setCountry = async (value) => {
        await this.setState({ selectedCountry: !value ? null : value })
        this.fetchCountryData()
    }


    fetchCountryData = async () => {
        let { selectedCountry } = this.state
        // https://covid19.mathdro.id/api/countries/egypt

        if (!selectedCountry) {
            this.fetchWorldData()
            return
        }

        await fetch(`https://covid19.mathdro.id/api/countries/${selectedCountry.code}`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                let resultConfirmed = result.confirmed.value
                let resultDeath = result.deaths.value
                let resultRecovered = result.recovered.value
                let resultLastUpdate = result.lastUpdate
                let resultActiveCases = result.confirmed.value - result.deaths.value - result.recovered.value
                this.setState({ resultConfirmed, resultDeath, resultRecovered, resultLastUpdate, resultActiveCases, foundData: true })

            })
            .catch(error => {
                console.error('Error:', error);
            });

    }
    fetchWorldData = async () => {
        // https://covid19.mathdro.id/api


        await fetch(`https://covid19.mathdro.id/api`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                let resultConfirmed = result.confirmed.value
                let resultDeath = result.deaths.value
                let resultRecovered = result.recovered.value
                let resultLastUpdate = result.lastUpdate
                let resultActiveCases = result.confirmed.value - result.deaths.value - result.recovered.value
                this.setState({ resultConfirmed, resultDeath, resultRecovered, resultLastUpdate, resultActiveCases, foundData: true })

            })
            .catch(error => {
                console.error('Error:', error);
            });

    }




    componentDidMount = () => {
        this.fetchWorldData()
    }

    render() {
        let { selectedCountry, foundData, resultConfirmed, resultDeath, resultRecovered, resultActiveCases, resultLastUpdate } = this.state
        // console.log('vallll: ', selectedCountry);

        return (
            <div className='home-container' id='home-container-id'>
                <h1>COVID-19 Tracker</h1>
                <SearchBar selectedCountry={selectedCountry} set={this.setCountry} />


                {!selectedCountry ? this.fetchWorldData : null}

                {!selectedCountry ? null : <ReactCountryFlag countryCode={selectedCountry.code} style={{ fontSize: '10em', lineHeight: '2em', }}
                    aria-label={selectedCountry.name} svg />
                }
                <div>

                </div>

                {
                    !foundData ? null :
                        <section className="text-gray-500 bg-gray-900 body-font result-section">
                            <div className="container px-5 py-24 mx-auto">
                                <div className="flex flex-col text-center w-full mb-20">
                                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
                                        statistics of {!selectedCountry ? 'the whole world' : selectedCountry.name}</h1>
                                    {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p> */}
                                </div>
                                <div className="flex flex-wrap -m-4 text-center cards-container">

                                    <DataCard title='Total Cases' data={resultConfirmed} icon={faVirus} id='card-item-1' />
                                    <DataCard title='Total Deaths' data={resultDeath} icon={faSkullCrossbones} id='card-item-2' />
                                    <DataCard title='Total Recoverd' data={resultRecovered} icon={faSmileBeam} id='card-item-3' />
                                    <DataCard title='Active Cases' data={resultActiveCases} icon={faProcedures} id='card-item-4' />


                                </div>


                            </div>
                            <h2>last update: {moment(Date.parse(resultLastUpdate)).calendar()}</h2>

                            {/* <FacebookShareButton url='https://covid19-82817.web.app/' quote={"take a look about current covid19 statistics"} style={{}} >
                                <FacebookIcon size={32} round={true} style={{margin:'auto'}} />
                                <h3>share this website with your friends</h3>
                            </FacebookShareButton> */}

                        </section>
                }
                <h2 style={{ marginTop: '4%', paddingBottom: '1%' }}>developed by
                    <a href='mailto:Madkour17@hotmail.com' style={{ color: '#f56565', fontWeight: '700', marginLeft: '5px', textDecoration: 'none' }}>
                        Mahmoud Madkour
                    </a>
                </h2>
            </div>
        );
    }
}

export default Home;