import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSkullCrossbones, faVirus, faSmileBeam, faProcedures } from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup';


class DataCard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.addHovering = this.addHovering.bind(this)
        this.removeHovering = this.removeHovering.bind(this)
    }


    addHovering = (val) => {
        document.getElementById(`${val}`).classList.add('animate__tada')
    }
    removeHovering = (val) => {
        document.getElementById(`${val}`).classList.remove('animate__tada')
    }

    render() {

        let { title, data, icon, id } = this.props

        return (
            <div className="p-4 md:w-1/4 sm:w-1/2 w-full card-item animate__animated" id={id}
                onMouseEnter={() => this.addHovering(id)} onMouseLeave={() => this.removeHovering(id)}>
                <div className="border-2 border-gray-800 px-4 py-6 rounded-lg card-item-inner">
                    <FontAwesomeIcon icon={icon} size='3x' className='icon' />
                    <h2 className="title-font font-medium text-3xl text-white">
                        <CountUp end={data} />
                    </h2>
                    <p className="leading-relaxed">{title}</p>
                </div>
            </div>
        );
    }
}

export default DataCard;